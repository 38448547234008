<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
        <img src="@/assets/images/app/article.jpg" :alt="title" class="img-fluid">
        <div class="position-absolute top-50 start-0 translate-middle-y w-100">
          <div class="container text-white px-3">
              <div class="row justify-content-end">
              <div class="col-lg-8 col-10 text-end">
                <h2 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('articles')}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-3 my-lg-5">
      <div class="row">
        <div class="col-12 px-4 px-md-3">
          <h1 class="text-primary display-22 animate__animated animate__slideInRight font-style-2 text-capitalize fw-semibold">
            {{  current.title }}
          </h1>
          <p class="text-muted animate__animated animate__slideInRight">{{$t('published_on')}}  {{current.date}}</p>
          <div class="article-content w-100">
            <article>
              <div class="embed-responsive ratio ratio-16x9" v-if="ids==0">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/kQ0-EBpQvJI"></iframe>
              </div>
              <div v-if="ids==1">
                <img src="@/assets/images/app/article/20230325.jpg" class="img-fluid rounded" :alt="current.title"/>
              </div>

              <div v-if="ids==2">
                <img src="@/assets/images/app/article/20230330.jpg" class="img-fluid rounded mb-3" :alt="current.title"/>
                <p>COLORECTAL Cancer Awareness Month is an annual observance in March. It aims to raise awareness about colorectal cancer and encouraging people to take steps to prevent and detect this disease. </p>

                <p>According to Malaysia National Cancer Registry (MNCR) 2012-2016, colorectal cancer is the second most common cancer among the general population in Malaysia. It is the most common cancer among males and the second most common cancer among females in Malaysia.</p>

                <p>Colorectal cancer is a type of cancer that affects the colon or rectum, which are part of the digestive system. It is one of the most common types of cancer, and typically occurs in older adults. However, in recent years, there has been a rise in the younger age group. </p>

                <p>Consultant colorectal surgeon from Prince Court Medical Centre, Dr Lim Hiong Chin notes, “The incidence of cancer increases with age and peaks at age 70.</p>

                <p>"The older we get, the higher the risk but we see a rise of colorectal cancer in the younger age group. For males, we see a rise between the ages of 50 and 64 years, and in females, the rise is between the ages of 35 and 64.”</p>

                <img src="@/assets/images/app/article/20230330-1.jpg" class="img-fluid rounded mb-3" :alt="current.title"/>
                <small class="font-size-12 d-block mb-3">Dr Lim Hiong Chin says the tricky part about colorectal cancer is the lack of clear signs, and they usually appear at the advanced stage. – Pic courtesy of Prince Court Medical Centre</small>

                <p>Several factors may contribute to this trend. One possible explanation is the dietary habits and lifestyle choices of Malaysians.</p>
                <p>Dr Lim points out that dietary habits which include processed food, high in red meat, and low in fibre such as fresh vegetables and fruits are part of the problem. </p>
                <p>He also blames the preference of the urban generation today who prefer eating out which may mean having little control over the way their food is prepared, as food that involves deep-frying and barbecuing with charred meat potentially contributes to the increase in colorectal cancer incidences.</p>
                <p>“These factors have a big impact on the development of colorectal cancer. Other factors like lack of physical activity, obesity and smoking have also been linked to an increased risk of colorectal cancer,” he adds. </p>
                <p>Another factor that may contribute to the increase in colorectal cancer incidence in younger patients is the lack of awareness and screening.</p>
                <p>Colorectal cancer screening is recommended for adults over the age of 50, but younger individuals may not be aware of the importance of screening or may not have access to it.</p>
                <p>Dr Lim stresses, “Screening is recommended at 50 years old, however with the trend suggesting an earlier onset of cancer, I recommend that screening should start as early as age 45.”</p>
                <img src="@/assets/images/app/article/20230412-2.jpg" class="img-fluid mb-3" :alt="current.title"/>
                <small class="font-size-12 d-block mb-3">Fresh vegetables and fruits are a good source of fibre. – Pixabay pic</small>

                <p>Early detection and treatment are crucial for improving colorectal cancer outcomes. The most effective way to detect colorectal cancer early is through screening tests, such as colonoscopy, the gold standard when it comes to detecting colorectal cancer. </p>

                <p>“Colonoscopy is a procedure where a fibre optic camera is passed into the colon through the anus. The patient must prepare themselves a day before by taking laxatives to cleanse their bowel.</p>

                <p>"The patient is sedated before the procedure, and the fibre optic camera is passed through to view the inner lining of the colon. If any abnormalities such as a tumour or polyp are detected, they can be removed at the same setting.</p>

                <p>"For straightforward, non-complicated cases, it will usually take only about 20 minutes as a daycare procedure,” Dr Lim explains.</p>

                <p>“Apart from colonoscopy, there other screening methods such as a stool test, where a test is done to determine the presence of blood in stools or evidence of bleeding, or a CT colonography CT colonography, where a CT scan is done to capture detailed images of the colon and rectum,” he adds.</p>

                <p>Signs and symptoms</p>

                <p>“The tricky part about colorectal cancer is the lack of clear signs, and they usually appear at the advanced stage. As such, there is a need to educate the public about the common signs and symptoms to look out for.</p>

                <p>“Some of the signs are blood in your stools or mucus in the stools, and recent change in bowel habits such as constipation or diarrhoea which persists for a long time despite treatment with no obvious explanation.</p>

                <p>"Other signs which are more indicative of the later stage of the disease include unexplained and non-specific abdominal pain, sudden weight loss without explanation and sudden appetite loss,” he reveals.</p>

                <p>Treatment and prognosis</p>

                <p>“There are in general three modalities of treatment for colorectal cancer. It comes in the form of surgery, radiotherapy, and systemic therapy.</p>
                
                <p>"And lately we have been hearing about targeted therapy and immunotherapy. However, for early cancer, surgery may be the only treatment needed.</p>
                
                <p>"For advanced cancer in Stage 3 and 4, it usually requires a combination of surgery and chemotherapy. With the right treatment, most people will be able to carry on with their lives, with many patients surpassing the five years survival rate,” says Dr Lim.</p>
                
                <p>Colorectal cancer is a growing concern in Malaysia, with an increasing number of younger patients being diagnosed.</p>
                
                <p>This trend is likely due to a combination of factors, including changing dietary habits and lifestyle choices, as well as a lack of awareness and screening.</p>
                
                <p>To address this issue, there is a need for increased education and awareness about colorectal cancer, as well as efforts to promote healthy lifestyle choices and improve access to screening.</p>
                
                <p>By taking these steps, we can reduce the burden of colorectal cancer in Malaysia and improve patient outcomes. - The Vibes, March 30, 2023</p>
                <p>Source:
                <a href="https://www.thevibes.com/articles/lifestyles/89173/colorectal-cancer-on-the-rise-within-the-younger-age-group\" target="_blank"> https://www.thevibes.com/articles/lifestyles/89173/colorectal-cancer-on-the-rise-within-the-younger-age-group\</a></p>
              </div>

              <div v-if="ids==3">
                <img src="@/assets/images/app/article/20230412.jpg" class="img-fluid rounded mb-3" :alt="current.title"/>
                <small class="font-size-12 d-block mb-3">There are no pain receptors in the colon, which would make the early stages of colorectal cancer (also known as colon cancer) asymptomatic.</small>
                <p>Lack of awareness on colorectal procedures and newer cancer treatments leave Malaysians in the dark</p>
                <p>MANY Malaysians find themselves at the end of an earth-shattering call from their doctor with a late-stage diagnosis of colorectal cancer - one of the most prevalent cancer in Malaysia.</p>
                <p>According to Prince Court Medical Centre consultant colorectal surgeon Dr Lim Hiong Chin, colorectal cancer (cancer of the colon and rectum) is one of the most common cancer among men - with lack of awareness as the main cause of late diagnosis.</p>
                <p>“The awareness of colorectal cancer in Malaysia is lacking and we see a lot of patients coming in at the later stages as compared to more developed countries.</p>
                <p>“Once we diagnose a more advanced stage of cancer, our options are limited - from minimal and less invasive surgery compared to major surgery, to the survival rate which is worse if the condition is picked up later.”</p>
                <p>Dr Lim says almost all colorectal cancers are asymptomatic at early stages.</p>
                <img src="@/assets/images/app/article/20230412-1.jpg" class="img-fluid rounded mb-3" :alt="current.title"/>
                <small class="font-size-12 d-block mb-3">‘It is the embarrassment of having to open themselves to the doctors and most people just live with it,’ says Dr Lim of people with haemorrhoids.</small>

                <p>Usually when people begin to complain or show symptoms they are most likely at an advanced stage. Even if they start to bleed they associate the bleeding with haemorrhoids.</p>
                <p>“What we fear is that the bleeding might not come from haemorrhoids but from something higher up. Therefore, if there are risk factors and the age group is right, any patient with haemorrhoids will be recommended for colonoscopy to ensure we do not miss the cancer, if any.</p>
                <p>“It is not uncommon for people to ignore haemorrhoids. It is the embarrassment of having to open themselves to the doctors and most people just live with it.”</p>
                <p>He says that conventionally haemorrhoids are treated with surgery but the downtime needed for recovery is not ideal for the productive workforce.</p>
                <p>“One of the latest treatments is laser haemorrhoid treatment. It involves making a small puncture in the haemorrhoid itself and passing laser fibre into the haemorrhoid, burning it from the inside.</p>
                <p>“It does two things. It clots the blood in the haemorrhoid immediately which causes it to shrink. This causes inflammation around the haemorrhoid.</p>
                <p>“Our body will heal itself naturally and inflammation will lead to scarring which is what we want. The scarring will pull the hemorrhoid to the wall,” he says, adding that the healing process can take a few months but the patient can go about his day as usual.</p>
                <p>Dr Lim says IV chemotherapy might not be able to reach every aspect of the body at times such as the abdomen. Therefore, hyperthermic intraperitoneal chemotherapy (HIPEC) is designed to solve this and the heated chemotherapy is delivered straight to the abdomen.</p>
                <p>“The aim is to remove all the microscopic cancer cells. Cancer cells that can be seen by the naked eye need to be removed surgically.</p>
                <p>“But HIPEC is not suitable for all cancers. The suitable cancers include cancer of the appendix, ovaries, stomach and primary cancer of the abdomen’s inner lining,” he says.</p>
                <p>Dr Lim says that HIPEC is a major surgery and the surgery itself takes a long time to complete, so a thorough evaluation of the patient needs to be made to achieve a certain amount of benefit before the procedure is offered.</p>
                <p>An incision will be made under the rib cage all the way till the upper pelvic bone, with the longest operation lasting some 15 hours for Dr Lim.</p>
                <p>“We won’t offer this treatment if the risk outweighs the benefits. The risk can be seen from two aspects – the risk of surgery and the risk of anaesthesia.</p>
                <p>“Risk of surgery is that we will need to remove all cancer with the naked eye.</p>
                <p>“If there are more things for us to remove, then the surgical risk will increase exponentially especially after eight to 10 hours on the operating table.</p>
                <p>“The risk of anaesthesia is similar. The longer we anaesthetise our patients, the more complications can develop,” he says.</p>
                <p>“To make the HIPEC procedure a success is not solely based on technical aspects but the support of the hospital as well,” he adds, explaining that support comes in the form of anaesthesiologists, nursing team, clinical oncologists and team, pharmacists, dietitians, physiotherapists, ICU and the ward.</p>
                <p>“I think the most important (part of the job) is the support. We can’t do it alone and we need help from other (specialists),” says Dr Lim.</p>
                <p>Source:
                <a href="https://www.thestar.com.my/starpicks/2023/04/12/take-rectal-bleeding-seriously" target="_blank"> https://www.thestar.com.my/starpicks/2023/04/12/take-rectal-bleeding-seriously</a></p>
                
              </div>
              <div v-if="ids==4">
              <div class="mb-4">{{ articles[4].smallDescription }}</div>
              <div class="embed-responsive ratio ratio-16x9">
                <iframe src="https://www.facebook.com/plugins/video.php?height=514&href=https%3A%2F%2Fwww.facebook.com%2FMELODYdotmy%2Fvideos%2F1086421208980704%2F&show_text=true&t=0" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" ></iframe>
              </div>
              </div>
             
            </article>
          </div>
        </div>
      </div>
    </div>
    
    <Enquiry />
    <Contact />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Enquiry from '@/views/pages/drlim/components/enquiry';
import Contact from '@/views/pages/drlim/components/contact';
/**
 * Starter page
 */
export default {
  components: { Layout , Header, Enquiry, Contact},
  page: {
    title: "Articles",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  data() {
    return {
      title: appConfig.title,
      items: [],
      ids:"",
      current:{},
      articles: [
        {
          title: "活力加油站 Living Delight (2020) | 2020年10月21日: 肛周脓肿，或因免疫力低下？",
          smallDescription: " 今日主题：● 肛周脓肿，或因免疫力低下？ ● 严重可引发肛瘘？ ● 如何远离肛周脓肿？● 谁是大主厨？ #活力加油站 #LivingDelight",
          image: require('@/assets/images/app/article/20201021.jpg'),
          date:"2020-01-21"
        },
        {
          title: "Early detection and diagnosis for prompt, cost-effective cancer interventions",
          smallDescription: "Indonesia marked this year’s World Cancer Day with a bold call from Health Minister Budi Gunadi Sadikin and others for widespread early screening for the disease so that it can be treated as effectively as possible.",
          image: require('@/assets/images/app/article/20230325.jpg'),
          date:"2023-03-25"
        },
        {
          title: "Colorectal cancer on the rise within the younger age group",
          smallDescription: "Dietary habits such as excessive processed food, consuming less fibre, and penchant for eating out are part of the problem.",
          image: require('@/assets/images/app/article/20230330.jpg'),
          date:"2023-03-20"
        },
        {
          title: "Take rectal bleeding seriously",
          smallDescription: "Lack of awareness on colorectal procedures and newer cancer treatments leave Malaysians in the dark.MANY Malaysians find themselves at the end of an earth-shattering call from their doctor with a late-stage diagnosis of colorectal cancer – one of the most prevalent cancer in Malaysia.",
          image: require('@/assets/images/app/article/20230412.jpg'),
          date:"2023-04-12"
        },
        {
          title: "MELODY健康星期四 - 生痔疮, 该怎么办?",
          smallDescription: "一旦患上痔疮，那将会是一种无法言喻的痛。那这种痛应该要如何才能治愈呢？生活上的哪些习惯会让你和痔疮的距离变得更近? 【早晨有意思】之【健康星期四】，有Prince Court Medical Centre普通及大肠外科顾问，林享懃医生和你讲解更多。#MELODY早晨有意思 #MELODY健康星期四",
          image: require('@/assets/images/app/article/20230420.jpg'),
          date:"2023-04-20"
        },

      ]
     
    };
  },
  created(){
    var id = this.$route.params.id || null
    if (id!==null) {
      this.ids = id
      console.log(parseInt(id))
      this.current = this.articles[parseInt(id)]
      document.title = `${this.capitalize(this.current.title)} | Dr. Lim Surgery - Consultant General & Colorectal Surgeon`;
    }
  },
  mounted(){
    
  },
  methods: {
    capitalize(string) {
      console.log(string)
     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
}
</script>
<style coped>
 a .opactiy-2{
  opacity:0.6;
  transition: 0.3s;
 }
 a:hover .opactiy-2{
  opacity:0.2;
 }
</style>